import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { StyledEngineProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import Home from "./pages/dashboard/Home";
import MarginTrading from "./pages/dashboard/MarginTrading";
import Onboarding from "./pages/dashboard/Onboarding";
import PrivacyPolicy from "./pages/dashboard/faq";

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route index element={<Onboarding />} />
            <Route path="margin-trading" element={<MarginTrading />} />
            <Route path="privacypolicy" element={<PrivacyPolicy />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </StyledEngineProvider>
  );
};
export default App;
