import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <section className="w-screen h-screen">
      <h1>PRIVACY NOTICE: MERISTEM SECURITIES LIMITED</h1>

      <p>
        Meristem Securities Limited understands that your privacy is important
        and that you care about how your personal data is used and shared
        online. We respect the privacy of everyone who visits our website and
        uses our online services.
      </p>

      <p>
        We are committed to respecting, securing, and protecting your personal
        data.We are also committed to being transparent about what we collect
        from you and how we use it.
      </p>

      <p>
        This privacy policy covers information practices, including how we use,
        share and secure the personal information you provide and your legal
        rights.
      </p>

      <p>
        Our contact details. <br />
        Address: 20A, Gerrard Road, Ikoyi, Lagos
      </p>
      <p>
        <strong>Email:</strong>contact@meristemng.com
        <br />
        <strong>Tel:</strong> 0800MERISTEM / 0700MERISTEM
      </p>

      <h2>Your Rights.</h2>

      <p>
        Under the Nigerian Data Protection Regulation (NDPR), you have several
        rights about your personal data. This policy has been designed to uphold
        your rights where we act as a data processor:
      </p>

      <ul>
        <li>
          Right of access : you have the right to request a copy of the
          information that we hold about you.
        </li>
        <li>
          Right of rectification : you have a right to correct data that we hold
          about you that is inaccurate or incomplete.
        </li>
        <li>
          Right to be forgotten : in certain circumstances you can ask for the
          data we hold about to be erased from our record.
        </li>
        <li>
          Right to restriction of processing : where certain conditions apply to
          havea right to restrict the processing.
        </li>
        <li>
          Right of portability : you have the right to have the data we hold
          about you transferred to another organization.
        </li>
        <li>
          Right to object : you have the right to object to certain types of
          processing such as direct marketing.
        </li>
        <li>
          Right to object to automated processing, including profiling : you
          also have the right not to be subject to automated processing or
          profiling.
        </li>
        <li>
          Right to judicial review : if we refuse your request under rights of
          access, we will provide you with a reason to why. You have the right
          to complain as detailed below.
        </li>
      </ul>

      <h2>Your right to complain.</h2>

      <p>
        If you wish to make a complaint about how your personal data is being
        processed or how your complaint has been handled, you have the right to
        lodge a complaint directly to our in-house data officer or with the
        supervisory authority.
      </p>

      <p>
        If we are unable to help, or you are not satisfied with our response,
        you also have the right to lodge a complaint with the Nigeria Data
        Protection Commission (NDPC). NDPC can be contacted:
      </p>

      <p>
        By post to: No. 5, Donau Crescent, Off Amazon Street, Maitama, Abuja,
        Nigeria.
        <br />
        Telephone: +234 (0) 916 061 5551
        <br />
        Email Address: info@ndpc.gov.ng
      </p>

      <p>
        We would, however, appreciate the chance to deal with your concerns
        before you approach NDPC, so please contact us in the first instance.
      </p>

      <p>
        <strong>What data do we collect from you?</strong>
      </p>

      <p>
        By expressing an interest in our services, visiting our websites or
        registering to use our service, we may collect personal information from
        you:
      </p>

      <ul>
        <li>
          Contact information such as name, company name, address, phonenumber
          and email address.
        </li>
        <li>
          Financial information such as billing name, billing address and
          credit/debit card details.
        </li>
        <li>
          System information, such as IP Address, browser type and version, and
          operating system relating to information gathering tools such as
          cookies.
        </li>
        <li>
          Other information such as the number of people within your
          organization using our service.
        </li>
      </ul>

      <h2>How we use your data.</h2>

      <p>
        The data we collect is used to educate and inform customers about our
        product and to improve our service.
      </p>

      <p>
        We may also collect personal information about our prospective and
        existing customers, employees, vendors, and service providers.{" "}
      </p>

      <p>
        The information collected is used to provide services to our customers.
        These may include but not limited to any of the following:
      </p>

      <ul>
        <li>Processing service requests</li>
        <li>Negotiating contracts</li>
        <li>Handling orders</li>
        <li>Processing payments</li>
        <li>Promotional offers and marketing</li>
        <li>Providing customer support</li>
        <li>Managing customer relationships</li>
        <li>Developing and improving our services </li>
        <li>
          Compliance with governmental, legislative, and regulatory bodies, and
          investigating complaints about the use of our services.
        </li>
      </ul>

      <p>
        We process personal information for certain business purposes, which
        include some or all the following:
      </p>

      <ul>
        <li>
          Where the processing enables us to ensure efficient transactional
          purposes.
        </li>
        <li>
          Where the processing enables us to enhance, modify, personalize, or
          otherwise improve our services/communications for the benefit of our
          customers.
        </li>
        <li>To better understand how people interact with our websites.</li>
      </ul>

      <li>
        We ensure that we only process data in the interests of our company in
        conducting and managing our business to enable us to give customers the
        best service/products and secure experiences. This is known as
        “Legitimate Interests”.{" "}
      </li>

      <li>
        We will only ever send you communications that we feel are relevant to
        you. We may process your information to protect you against fraud when
        transacting on our website, and to ensure our websites and systems are
        secure.
      </li>

      <li>
        When processing your personal information for our legitimate interests,
        we consider and balance any potential impact on you, both positive and
        negative and your rights under data protection laws.
      </li>

      <li>
        Our legitimate business interests do not automatically override your
        interests. As such, we will not use your personal data for activities
        where our interests are overridden by the impact on you, unless we have
        your consent or are otherwise required or permitted to by law.
      </li>

      <li>{`If you have any objection to our data processing by legitimate interests, please. see "your rights" section at the start of this policy.`}</li>

      <p>Storing and sharing your information.</p>

      <h2>SUB-PROCESSORS</h2>

      <p>Who is a sub-processor?</p>

      <p>
        In summary, a sub-processor is a third-party company engaged by us to
        assist with data processing.
      </p>

      <p>
        We set a high standard in selecting sub-processors to ensure that they
        are secure, maintain strict privacy policies and adhere to
        confidentiality practices in processing data.
      </p>

      <p>
        We may share your data with these companies to provide services on both
        our and your behalf. These service providers, under contractual
        obligations, will use your personal information only as necessary to
        provide the requested services to us. We will not sell, rent, or trade
        any information with third parties for promotional or marketing
        purposes.{" "}
      </p>

      <p>
        The use of our service requires the sharing of data with sub-processors
        which are subject to rigorous privacy statements. To use our service,
        you must agree with our use of sub-processors.
      </p>

      <h3>BILLING</h3>

      <p>
        We use a third-party service provider to process orders. These
        third-party services are not permitted to store, retain, or use any
        billing information provided by us.
      </p>

      <h3>Disclosure.</h3>

      <p>
        If required by law or if disclosure is necessary to protect our rights
        and/or to comply with judicial proceedings, court order, or legal
        process, we reserve the right to disclose any relevant information.
      </p>

      <h3>Communication.</h3>

      <p>
        When you provide information through one of our webforms or other
        avenues, we might provide you with further information about our
        services. You can manage your subscription preferences at any time and
        unsubscribe from this information.
      </p>

      <p>
        Should you request information from us, we will use the data you provide
        to process this request.
      </p>

      <p>{`For example, by completing our "Access request form", we will get a better understanding of your requirements.`}</p>

      <p>
        We may use the information you provided to contact you to further
        discuss your interest in our services and to send you information
        regarding us and our affiliates. We will only process the information
        you provide on the grounds of legitimately meeting your requirements.
      </p>

      <p>
        To consistently provide an expected level of service, we may also use
        information collected to update, expand, and analyze our records to
        create a more befitting service for you. For further enquiries about
        receiving marketing communications, contact us or telephone:
        0800MERISTEM; 0700MERISTEM
      </p>

      <h3>Keeping your data up to date.</h3>

      <p>
        We may retain your information for a period consistent with the original
        reason it was collected. Upon entering into an agreement with us, we
        reserve the right to store and use your information. Upon termination of
        the agreement, we would keep your information for required legal
        reasons.
      </p>

      <p>
        As a customer, you have the right to request to review, correct, delete
        or otherwise modify any of the personal information you may have
        previously provided to us, at any time. As a user of our product, you
        may generally update information wherever necessary. To update your
        billing information or terminate your agreement with us, you can contact
        us.
      </p>

      <p>
        For other requests: erasure, access, portability, rectification, and
        objection, your requests will be addressed within thirty (30) days.
      </p>

      <h3>Your data.</h3>

      <p>
        Our customers submit data and information, whether electronically or by
        other means, to us for processing purposes. We will not review, share,
        alter or distribute this data unless otherwise requested by law. We only
        access your data only for the purposes of continuing to provide our
        services or to prevent or address any service or technical problems.
      </p>

      <p>
        As stated above, the information we hold about you can be accessed upon
        request.{" "}
      </p>

      <h3>Cookies</h3>

      <p>
        Our website uses cookies. A cookie is a small file of letters and
        numbers that we put on your computer if you agree. These cookies allow
        us to distinguish you from other users of our website, which helps us to
        provide you with a good experience when you browse our website and also
        allows us to improve our site. You can set your browser to refuse all or
        some browser cookies, or to alert you when websitesset or access
        cookies. If you disable or refuse cookies, please note that some partsof
        this website may become inaccessible or not function properly.
      </p>

      <h3>Security.</h3>

      <p>
        As part of delivering optimum service to our customers, we take extra
        security measures to maintain your data in a secure and organised
        manner. Only authorised employees from our organization can access,
        alter, disclose and destroy personal data, when required.
      </p>

      <p>
        In addition, these employees only act within the scope of their
        authority and are trained to prevent any errors or omissions.
      </p>

      <p>
        We also carry out ongoing security monitoring exercises to ensure that
        our systems are updated and adopt appropriate industry data collection,
        storage, and processing practices.
      </p>

      <p>
        Physical security measures are in place to protect against unauthorized
        access, alteration, disclosure of personal information, username,
        password, transaction information and data stored in your user account.
        Access to your name and address is restricted to our employees who need
        to know such information in connection with delivering our services to
        you and are bound by legalconfidential obligations.
      </p>

      <p>
        We reserve the right to change this privacy policy. All enquiries about
        this privacy policy should be directed to contact
        compliance@meristemng.com
      </p>

      <h3>Governing Law</h3>

      <p>
        This privacy policy is made pursuant to the Nigeria Data Protection Act,
        2023 (or any subsequent amendments thereof) and other relevant Nigerian
        laws, regulations or international conventions applicable to Nigeria.
        Where any provision of this Policy is deemed inconsistent with a law,
        regulation or convention,such provision shall be subject to the
        overriding law, regulation or convention.{" "}
      </p>

      <h2>
        <strong>Effective: April 2021</strong>
      </h2>
    </section>
  );
};

export default PrivacyPolicy;
