import React from "react";
import BankDetailsTable, { banks } from "../../components/BankDepositColumn";
import DiscoverNewDynamicsOfTrading from "../../components/DiscoverNewDynamicsOfTrading";
import DiscoverTrueMeaning, {
  discoverList,
} from "../../components/DiscoverTrueMeaning";
import DownloadItem, { downloadLinks } from "../../components/DownloadItem";
import Footer from "../../components/Footer";
import HowItWorks from "../../components/HowItWorks";
import Investment from "../../components/Investment";
import Navigation from "../../components/Navigation";
import PoweredByMeristem from "../../components/PoweredByMeristem";
import ScrollDown from "../../components/ScrollDown";
import TradeOnTheGo from "../../components/TradeOnTheGo";

const Home: React.FC = () => {
  return (
    <div className="w-full h-auto pt-32 bg-cover text-primary-light md:px-16 bg-onboarding-gradient ">
      <Navigation />
      <DiscoverNewDynamicsOfTrading />
      <ScrollDown />
      <DiscoverTrueMeaning discoverList={discoverList} />
      <PoweredByMeristem />
      <HowItWorks />
      <BankDetailsTable banks={banks} />
      <Investment />
      <DownloadItem downloadLinks={downloadLinks} />
      <TradeOnTheGo />
      <Footer />
    </div>
  );
};

export default Home;
