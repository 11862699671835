import React, { memo } from "react";
import Faqs, { faqs } from "../components/Faqs";

export const MarginTradingFAQ: React.FC = () => {
  return (
    <div
      className="flex flex-col items-center justify-center w-full h-auto mb-16 mt-15"
      id="faqs"
    >
      <h1 className="mb-8 text-4xl text-center">Frequently Asked Questions</h1>
      <p className="mb-16 text-lg text-center opacity-80">
        More questions? Kindly check our FAQs to get the answers
      </p>
      <Faqs faqs={faqs} />
    </div>
  );
};

export default memo(MarginTradingFAQ);
