import { ArrowDropDown } from "@mui/icons-material";
import { memo, useState } from "react";
import marginLendingProcess from "../../src/asset/downloads/margin_lending_processs.png";

type FaqsType = {
  id: string;
  title: string;
  message: string[];
};

export const faqs: FaqsType[] = [
  {
    id: "1",
    title: "What Is the Margin Lending? ",
    message: [
      "Margin lending simply means borrowing money to invest in shares selected from an approved list, using shares as security/collateral.",
    ],
  },
  {
    id: "2",
    title: "What are the benfits to investors? ",
    message: [
      "Leverage Benefits: Margin lending allows an investor to increase the value of securities they already own to increase the size of their investment.",
      "Diversification of Portfolio: If your portfolio is dominated by a large block of stock from one company/sector, with a margin account, you may be able to use those shares as collateral for a margin loan. You can then use the loan proceeds to diversify your portfolio without having to sell your original shares of stock.",
      "A convenient line of credit: It is an easily accessible line of credit provided all the requirements are met.",
    ],
  },
  {
    id: "3",
    title: "What is the Risks in Margin Lending? ",
    message: [
      "Market volatility, margin calls and the risk of losing assets. If the market declines, so will the value of your portfolio.",
      "You can lose more money than you have invested if price expectation does not materialize;",
      "You may have to deposit additional cash or securities in your account on short notice to cover market losses;",
      "You may be forced to sell some or all your securities when falling stock prices reduce the value of your securities;",
      "Interest rates (cost of funds) may change (go higher than expected)",
    ],
  },
  {
    id: "4",
    title: "How to much can I borrow? ",
    message: [
      "The amount that you can borrow is determined by the securities in your portfolio, their LOAN TO VALUE RATIO (LVR) and a credit limit based on an assessment of your financial position as determined by your broker.",
    ],
  },
  {
    id: "5",
    title: "How can I minimize Risks Involved? ",
    message: [
      "Consider leaving a cash cushion in your account to help reduce the likelihood of a MARGIN CALL.",
      "Understanding that your broker charges you interest for borrowing money and how that will affect the total return on your investments.",
      "Prepare for volatility; position your portfolio to withstand significant fluctuations in the overall value of your collateral without falling below your minimum equity requirement",
      "Being aware that not all securities can be purchased on margin (i.e. MARGINABLE AND NON_MARGINABLE STOCKS).",
      "Invest in assets with significant return potential; the securities you buy on margin should, at a minimum, have the potential to earn more than the cost of interest on the loan.",
      "Set a personal trigger point; keep additional financial resources in place to contribute to your margin account when your balance approaches the MARGIN MAINTENANCE REQUIREMENT",
      "Pay interest regularly; interest charges are posted to your account monthly, so it makes sense to pay them down before they build to unmanageable levels.",
      "Asking your broker whether trading on margin is appropriate for you after taking cognizance of your financial resources, investment objectives, and tolerance for risk.",
      "Learning more about how a margin account works and what happens if the price of the securities purchased on margin declines.",
    ],
  },
  {
    id: "6",
    title: "What is Margin Call And Maintenance Margin? ",
    message: [
      "A margin call occurs when a margin account runs low on funds, usually because of a losing trade. Margin calls are demands for additional capital or securities to bring a margin account up to the minimum maintenance margin. Brokers may MARGIN LENDING FAQs V. force traders to sell assets, regardless of the market price, to meet the margin call if the trader does not deposit funds.",
    ],
  },
  {
    id: "7",
    title: "How can I start with Meristem? ",
    message: [
      "Step 1: Sign the margin agreement",
      "Step 2: Fill and sign detachment form with the stocks you intend to use as collateral.",
      "Step 3: Your account is opened, and funds will be disbursed within 48hours after forms have been received.",
      "Step 4: You are sent feedback after proper review of your portfolio for qualifying marginable stocks.",
    ],
  },
  {
    id: "8",
    title: "How does it work? ",
    message: [marginLendingProcess],
  },
];

const Faqs: React.FC<{ faqs: FaqsType[] }> = ({ faqs }) => {
  const [activeId, setActiveId] = useState<string>("");

  const toggleMessage = (id: string): void => {
    activeId === id ? setActiveId("") : setActiveId(id);
  };

  return (
    <section>
      {faqs.map((item) => (
        <section key={item.id} onClick={() => toggleMessage(item.id)}>
          <div className="flex flex-row items-center justify-between gap-20 p-8 mb-8 rounded-2xl bg-opacity-5 bg-slate-50">
            <span>{item.title}</span>
            <ArrowDropDown className="w-8 h-8" />
          </div>
          {activeId === item.id && (
            <div className="pl-8 mb-4">
              {item.message.map((value, index) => (
                <p className="pl-8 mb-4 " key={index}>
                  {item.id === "8" ? (
                    <img
                      src={marginLendingProcess}
                      className="w-full h-[60vh]"
                      alt="margin_lending_process"
                    />
                  ) : (
                    value
                  )}
                </p>
              ))}
            </div>
          )}
        </section>
      ))}
    </section>
  );
};

export default memo(Faqs);
