import { memo } from "react";
import MarginTradingTable, { tradings } from "./MarginTradingTable";

function MagicTradingOnMeritrade() {
  return (
    <div className="flex flex-col w-full my-16 md:flex-row">
      <div className="flex flex-col items-start justify-between h-auto gap-8 my-16 md:w-1/2">
        <h1 className="text-6xl leading-[1.5]">
          MARGIN TRADING <br />
          ON MERITRADE
        </h1>
        <p className="w-full text-sm opacity-80">
          The only stock broking firm in Nigeria to offerMargin Trading. We are
          giving you the edge to buy more stocks without breaking the bank.
          <span>Do you want more information on Margin Trading?</span>
        </p>
        <a
          href="#faqs"
          className="px-8 py-4 text-sm text-black rounded-lg bg-primary-light hover:bg-primary-dark hover:text-primary-light"
          target=""
        >
          Read our FAQs
        </a>
      </div>
      <MarginTradingTable tradings={tradings} />
    </div>
  );
}

export default memo(MagicTradingOnMeritrade);
