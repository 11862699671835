import { memo } from "react";
import fcmb from "../asset/images/fcmb-icon.png";
import fidelity from "../asset/images/fidelity.png";
import japaul from "../asset/images/japaul-gold-and-ventures-plc--big 1.png";
import universal from "../asset/images/universal-insurance-plc.png";

type MarginTradingProps = {
  id?: string;
  image: string;
  company: string;
  margin: string;
};

export const tradings: MarginTradingProps[] = [
  {
    id: "1",
    image: fcmb,
    company: "FCMB GROUP PLC. (FCMB)",
    margin: "6.55 0.23 (3.64%)",
  },
  {
    id: "2",
    image: universal,
    company: "UNIVERSAL INSURANCE PLC",
    margin: "0.24 0.00 (0.00%)",
  },
  {
    id: "3",
    image: japaul,
    company: "JAPAUL GOLD AND VENTURES LTD",
    margin: "1.03 0.01 (0.98%)",
  },
  {
    id: "4",
    image: fidelity,
    company: "FIDELITY BANK PLC",
    margin: "8.60 0.10 (1.18%)",
  },
];

const MarginTradingTable: React.FC<{ tradings: MarginTradingProps[] }> = ({
  tradings,
}) => {
  return (
    <div className="hidden p-4 mx-auto my-16 text-sm bg-green-900 rounded-lg shadow-lg md:w-1/2 md:flex">
      <div className="overflow-x-scroll">
        <table className="w-full h-96">
          <tbody>
            {tradings.map((trading) => (
              <tr
                key={trading.id}
                className={`mb-4 shadow-lg  bg-slate-50 bg-opacity-5 rounded-lg ${
                  Number(trading.id) === 2 ? "rotate-[-2deg]" : ""
                }`}
              >
                <td className=" text-start">
                  <img
                    src={trading.image}
                    alt={trading.company}
                    className="w-12 h-12 text-start"
                  />
                </td>
                <td className="w-auto text-start ">{trading.company}</td>
                <td
                  className={`w-auto text-start  ${
                    Number(trading.id) === 2 ? "text-alert " : ""
                  } `}
                >
                  {trading.margin}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(MarginTradingTable);
