import { memo, useState } from "react";
import { Link } from "react-router-dom";
import VideoList, { videos } from "./VideoList";

const HowItWorks: React.FC = () => {
  const [showVideo, setShowVideo] = useState<boolean>(false);
  return (
    <div id="how-it-works" className="w-full h-auto p-8 mt-16 md:p-8">
      <div className="">
        <h1 className="mt-24 mb-8 text-4xl text-center">How It Works</h1>
        <div className="flex flex-row items-center justify-around w-full gap-2 mb-8">
          <div
            onClick={() => setShowVideo(false)}
            className={`flex flex-row items-center justify-center w-1/2 h-12 gap-2 overflow-hidden border-solid rounded-lg border-primary-dark bg-primary-deep-dark hover:border-2 ${
              showVideo ? "" : "border-2 border-primary-dark"
            }`}
          >
            <img
              src="https://file.rendit.io/n/emPtfo9CrE8XvblaW86x.svg"
              className="w-6 shrink-0"
              alt=""
            />
            <div className="text-sm font-semibold text-center text-white">
              Opening an account
            </div>
          </div>
          <div
            onClick={() => setShowVideo(true)}
            className={`flex flex-row items-center justify-center w-1/2 h-12 gap-2 overflow-hidden rounded-lg bg-primary-deep-dark hover:border-2 border-primary-dark ${
              showVideo ? "border-2 border-primary-dark" : ""
            }`}
          >
            <img
              src="https://file.rendit.io/n/5eW0s5yhPPsyeDtbaF81.svg"
              className="w-6 shrink-0"
              alt=""
            />
            <div className="text-sm font-semibold text-center text-white">
              Videos
            </div>
          </div>
        </div>
      </div>
      <div
        className={`w-full rounded-lg shadow-md bg-opacity-5 shadow-green-400 ${
          showVideo ? "hidden" : "flex"
        }`}
      >
        <ul className="pl-8 marker:text-sm list-decimal leading-[2] my-8 text-primary-dull-text ">
          <li className="mb-4 text-2xl">
            Click on the{" "}
            <Link
              to="https://app.meritrade.com/auth/register"
              className="underline italics text-secondary"
            >
              open an account
            </Link>
            {"  "} link above and fill the account opening form (CSCS account
            will be opened within 24 to 48 hours)
          </li>
          <li className="text-2xl">
            Required KYC documents for Nigerian Citizens
          </li>
          <ul className="pl-8 mb-4 list-disc ">
            <li>
              Means of identification (Driver&apos;s license, National I.D,
              Permanent Voter&apos;s Card or International passport)
            </li>
            <li>
              Proof of residential address (Current Utility Bill: Electricity,
              Telephone, Waste Management Bill, Water Bill) no later than 3
              months
            </li>
            <li>Passport photograph & Signature specimen</li>
          </ul>
          <li className="text-2xl">Required KYC for Non-Nigerian Residents </li>
          <ul className="pl-8 mb-4 list-disc">
            <li>Valid means of ID (Notarized if Non-Nigerian issued)</li>
            <li>Notarized recent Utlilty Bill of not more than 3 months old</li>
            <li>Passport Photograph</li>
            <li>Signature specimen</li>
          </ul>
          <li className="text-2xl">
            Fund your account with a minimum of N10,000
          </li>
        </ul>
      </div>
      <VideoList videos={videos} showVideo={showVideo} />
    </div>
  );
};

export default memo(HowItWorks);
