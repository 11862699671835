import React, { memo } from "react";

const ScrollDown: React.FC = () => {
  return (
    <div className="flex flex-row items-center justify-center w-full gap-3 my-8">
      <img
        src="https://file.rendit.io/n/zksFAMNSGwGZ7UBerO4q.svg"
        className="self-start w-5 shrink-0"
        alt="scroll"
      />
      <div className="text-xs font-semibold text-[rgba(249,_249,_249,_0.6)]">
        Scroll down
      </div>
    </div>
  );
};

export default memo(ScrollDown);
