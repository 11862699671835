//refactor this after completion

import { memo } from "react";
import { Link } from "react-router-dom";
import facebook from "../asset//images/facebook.png";
import linkedin from "../asset//images/linkedin.png";
import twitter from "../asset//images/twitter.png";
import meristemLogo2 from "../asset/images/meritrade-logo-2.png";

const Footer: React.FC = () => {
  return (
    <div className="flex flex-col items-start justify-around w-full gap-8 p-8 text-xs bg-green text-primary-light">
      <div className="flex flex-col items-start justify-between w-full gap-4 md:flex-row">
        <img className="w-48 h=15" src={meristemLogo2} alt="" />
        <div className="flex flex-row items-start justify-between gap-8">
          <div className="flex flex-col mb-8">
            <h4 className="mb-2 font-extrabold">Meristem Subsidiaries</h4>
            {[
              {
                id: "1",
                title: "Meristem Wealth",
                link: "https://meristemwealth.com",
              },
              {
                id: "2",
                title: "Meristem Stockbrokers",
                link: "http://www.meristemstockbrokers.com",
              },
              {
                id: "3",
                title: "Meristem Registrar",
                link: "https://meristemregistrars.com",
              },
              {
                id: "4",
                title: "Meristem Trustees",
                link: "https://meristemtrustees.com",
              },
              {
                id: "5",
                title: "Meristem Family Office",
                link: "https://meristemtrustees.com",
              },
              {
                id: "6",
                title: "Meristem Finance",
                link: "https://meristemng.com",
              },
              {
                id: "7",
                title: "Meristem Capital",
                link: "https://meristemng.com",
              },
            ].map((item, _) => (
              <a
                key={item.id}
                href={item.link}
                className="transition duration-500 ease-in-out delay-50 hover:translate-x-1 hover:scale-60 hover:underline hover:text-yellow-500"
              >
                {item.title}
              </a>
            ))}
          </div>
          <div className="flex flex-col">
            <h4 className="mb-2 ">Quick Links</h4>
            {[
              { id: "1", title: "How it works", link: "/#how-it-works" },
              { id: "2", title: "Margin Trading", link: "/margin-trading" },
              { id: "3", title: "Why Meritrade", link: "/#why-meritrade" },
              { id: "4", title: "Platform", link: "/#platform" },
              {
                id: "5",
                title: "FAQ",
                link: "https://knowledge.meritrade.com",
              },
              { id: "6", title: "Downloads", link: "/#downloads" },
            ].map((item, _) => (
              <a
                key={item.id}
                href={item.link}
                target={`${item.title.toLowerCase() === "faq" && "_blank"}`}
                className="transition duration-500 ease-in-out delay-100 hover:translate-x-1 hover:scale-60 hover:underline hover:text-yellow-500"
              >
                {item.title}
              </a>
            ))}
          </div>
        </div>
        <div>
          <div className="flex flex-col mb-8">
            <h4 className="mb-2">Contacts</h4>
            {[
              { id: "1", title: "+234-1-2717353", link: "tel:+234-1-2717353" },
              { id: "2", title: "+234-1-2717351", link: "+234-1-2717351" },
              { id: "3", title: "+234-1-2717350", link: "+234-1-2717350" },
              { id: "4", title: "+234-1-2717352", link: "+234-1-2717352" },
              {
                id: "6",
                title: "0800-MERISTEM(080063747836)",
                link: "0800-MERISTEM(080063747836)",
              },
            ].map((item, _) => (
              <a
                key={item.id}
                href={item.link}
                className="transition duration-500 ease-in-out delay-100 hover:translate-x-1 hover:scale-60 hover:underline hover:text-yellow-500"
              >
                {item.title}
              </a>
            ))}
          </div>
          <div>
            <h2 className="mb-1">Email Address</h2>
            <a
              href="mailto:meritrade@meristemng.com"
              className="mb-1 hover:underline hover:text-yellow-500"
            >
              meritrade@meristemng.com
            </a>
          </div>
        </div>
        <div>
          <ul className="mb-8">
            <h4 className="mb-1">Address</h4>
            <li>20a Gerrard Road, Ikoyi 106104, Lagos.</li>
          </ul>
          <div>
            <div className="flex flex-row items-center justify-start gap-2">
              <Link to="https://twitter.com/meristemnigeria">
                <img
                  className="w-10 h=10 hover:border-b-2 hover:border-r-2 hover:rounded-xl hover:border-primary-dark"
                  src={twitter}
                  alt=""
                />
              </Link>
              <Link to="https://www.linkedin.com/company/meristem-securities-limited/?originalSubdomain=ngx">
                <img
                  className="w-10 h=10 hover:border-b-2 hover:border-r-2 hover:rounded-xl hover:border-primary-dark"
                  src={linkedin}
                  alt=""
                />
              </Link>
              <Link to="https://web.facebook.com/MeristemNigeria/">
                <img
                  className="w-10 h=10 hover:border-b-2 hover:border-r-2 hover:rounded-xl hover:border-primary-dark"
                  src={facebook}
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full gap-8 p-4 bg-opacity-50 border-t rounded-lg border-secondary md:justify-between md:items-start md: md:flex-row">
        <p>© 2023 Copyright: Meristem</p>
        <p>
          <Link to="#">All Right Reserved</Link> |{" "}
          <Link to="#">Terms & Conditions</Link> |{" "}
          <Link to="privacypolicy">Privacy Policy</Link>
        </p>
      </div>
    </div>
  );
};
export default memo(Footer);
