import { memo } from "react";
import appStore from "../asset/images/appstore.png";
import googlePlay from "../asset/images/gplay.png";
import iPhone13 from "../asset/images/iphone_13.png";

const TradeOnTheGo: React.FC = () => {
  return (
    <div
      id="platform"
      className="flex flex-col items-center justify-center w-full gap-4 px-4"
    >
      <h1 className="text-lg md:text-3xl mt-36 md:mt-24">Trade On The Go!</h1>
      <p className="text-center">
        With an online dashboard and a mobile app, you'll have full control of
        the market to grow your account with more convenience.
      </p>
      <div className="flex flex-row items-center justify-center w-full gap-4">
        <a href="https://apps.apple.com/us/app/meritrade/id977562417?ls=1">
          <img
            src={appStore}
            alt="app store logo"
            className="h-16 border-4 rounded-lg hover:border-primary-dark max-w-48"
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.meristem.meritradeapp&pli=1">
          <img
            src={googlePlay}
            alt="Google Play logo"
            className="h-16 border-4 rounded-lg max-w-48 hover:border-primary-dark"
          />
        </a>
      </div>
      <img src={iPhone13} alt="Iphone13" className="w-full h-auto md:w-96" />
    </div>
  );
};
export default memo(TradeOnTheGo);
