import { memo } from "react";
import access from "../asset/images/access.png";
import fidelity from "../asset/images/fidelity.png";
import firstbank from "../asset/images/firstbank.png";
import gtb from "../asset/images/gtb.png";
import wema from "../asset/images/wemabank.png";
import zenith from "../asset/images/zenith.png";

type BankDetailsProps = {
  id?: string;
  image: string;
  bank: string;
  account: string;
  name: string;
  branch: string;
};

export const banks: BankDetailsProps[] = [
  {
    id: "1",
    image: zenith,
    bank: "Zenith Bank",
    account: "1014048886",
    name: "Meristem Stockbrokers Limited",
    branch: "Ajose Adeogun Road",
  },
  {
    id: "2",
    image: gtb,
    bank: "GUARANTY BANK",
    account: "0149543092",
    name: "Meristem Stockbrokers Limited",
    branch: "Awolowo Road",
  },
  {
    id: "3",
    image: firstbank,
    bank: "First Bank",
    account: "2025196348",
    name: "Meristem Stockbrokers Limited",
    branch: "Awolowo Road",
  },
  {
    id: "4",
    image: access,
    bank: "Access Bank",
    account: "0690035760",
    name: "Meristem Stockbrokers Limited",
    branch: "Danmole, Victoria Island",
  },
  {
    id: "5",
    image: fidelity,
    bank: "Fidelity Bank",
    account: "1014048886",
    name: "Meristem Stockbrokers Limited",
    branch: "Awolowo Branch",
  },
  {
    id: "6",
    image: wema,
    bank: "Wema Bank",
    account: "0122805303",
    name: "Meristem Stockbrokers Limited",
    branch: "Ikeja Branch",
  },
];

const BankDetailsTable: React.FC<{ banks: BankDetailsProps[] }> = ({
  banks,
}) => {
  return (
    <div className="p-8 mx-auto my-8 max-w-max md:p-0">
      <h1 className="my-4 text-2xl font-bold text-center">Our Bank Details</h1>
      <p className="w-full px-12 text-center">
        You can use the direct credit option or make a transfer to our
        designated accounts below.
      </p>
      <p className="w-full pb-4 mt-16 opacity-50 text-start text-slate-60">
        Use your meritrade account number as the reference number
      </p>

      <div className="overflow-x-scroll">
        <table className="min-w-full">
          <thead className="bg-slate-50 bg-opacity-10">
            <tr>
              <th className="px-4 py-2 text-start md:w-10">Bank</th>
              <th className="px-4 py-2 text-start md:w-10">Account</th>
              <th className="px-4 py-2 text-start md:w-10">Name</th>
              <th className="px-4 py-2 text-start md:w-10">Branch</th>
            </tr>
          </thead>
          <tbody>
            {banks.map((bank) => (
              <tr key={bank.id} className="">
                <td className="px-4 py-2 text-start md:w-10">
                  <img
                    src={bank.image}
                    alt={bank.bank}
                    className="w-8 h-8 text-start md:w-10"
                  />
                </td>
                <td className="px-4 py-2 text-start md:w-10">{bank.account}</td>
                <td className="px-4 py-2 text-start md:w-10">{bank.name}</td>
                <td className="px-4 py-2 text-start md:w-10">{bank.branch}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(BankDetailsTable);
