import { memo } from "react";
import accountOpeningForm from "../asset/downloads/account_opening_form.pdf";
import corporateAccountOpening from "../asset/downloads/corporate-account-opening.pdf";
import fgnsbForm from "../asset/downloads/fgnsb_form.pdf";
import intermemberForm from "../asset/downloads/inter-member-form.pdf";
import nasdTransferForm from "../asset/downloads/nasd-transfer-form.pdf";
import shareTransferForm from "../asset/downloads/share-transfer-form.pdf";
import updateForm from "../asset/downloads/updateform.pdf";

type DownloadLinkType = {
  id: string;
  title: string;
  pdf?: string;
};

export const downloadLinks: DownloadLinkType[] = [
  {
    id: "1",
    title: "Corporate Account Form",
    pdf: corporateAccountOpening,
  },
  {
    id: "2",
    title: "Individual Account Form",
    pdf: accountOpeningForm,
  },
  {
    id: "3",
    title: "Inter-member Transfer Form",
    pdf: intermemberForm,
  },
  {
    id: "4",
    title: "Joint Account Opening Form",
    pdf: "#",
  },
  {
    id: "5",
    title: "NASD Transfer Form",
    pdf: nasdTransferForm,
  },
  {
    id: "6",
    title: "Share Transfer Form",
    pdf: shareTransferForm,
  },
  {
    id: "7",
    title: "Update Form",
    pdf: updateForm,
  },
  {
    id: "8",
    title: "FGN Savings Bond Form",
    pdf: fgnsbForm,
  },
];

const DownloadItem: React.FC<{ downloadLinks: DownloadLinkType[] }> = ({
  downloadLinks,
}) => {
  return (
    <div
      id="downloads"
      className="flex flex-col items-center w-full gap-4 px-4 my-16 justify-betwwen"
    >
      <h1 className="mb-8 text-2xl mt-36 md:mt-24 md:text-4xl">Downloads</h1>
      <ul className="flex flex-col flex-wrap items-center justify-center w-full gap-2 md:flex-row">
        {downloadLinks.map((item) => (
          <a
            href={item.pdf}
            key={item.id}
            className="w-full p-4 text-center rounded-lg md:mx-0 md:w-1/4 bg-primary-deep-dark hover:border border-secondary"
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.title}
          </a>
        ))}
      </ul>
    </div>
  );
};
export default memo(DownloadItem);
