import { memo } from "react";
import { Link } from "react-router-dom";
import graph from "../asset/images/wave.png";

const DiscoverNewDynamicsOfTrading: React.FC = () => {
  return (
    <div className="flex flex-col w-full p-8 md:pt-32 md:px-0 md:h-auto md:flex-row">
      <div className="flex flex-col md:w-1/2">
        <div className="text-4xl md:text-6xl text-primary-light ">
          <div className="my-8">DISCOVER THE </div>
          <div className="my-8">NEW DYNAMICS </div>
          <div className="my-8">OF TRADING</div>
        </div>
        <p className="mb-8 text-sm text-primary-dull-text">
          Take control of your financial security
        </p>
        <div className="flex flex-row items-start justify-between w-full gap-1">
          <Link
            to="https://app.meritrade.com/auth/register"
            className="flex flex-col items-center justify-center w-1/2 h-12 text-sm font-semibold text-black rounded-lg bg-primary-light hover:bg-primary-deep-dark hover:text-primary-light"
          >
            Get started
          </Link>
          <a
            href="#how-it-works"
            className="flex flex-row items-center w-1/2 gap-2 text-sm font-semibold rounded-lg bg-primary-dark hover:bg-primary-light hover:text-primary-deep-dark text-primary-light"
          >
            <div className="border-solid  bg-primary-deep-dark border-primary-dark bg-[linear-gradient(180deg,_rgba(11,_111,_65,_0.1)_0%,rgba(20,_77,_51,_0.1)_100%)] bg-cover bg-50%_50% bg-blend-normal bg-no-repeat self-start flex flex-col justify-center w-12 shrink-0 h-12 items-center border-2 rounded-[100px]">
              <img
                src="https://file.rendit.io/n/tQVxzCvZ8Tq9bAUrN3xA.svg"
                className="w-6"
                id="Polygon"
                alt="none"
              />
            </div>
            How it works
          </a>
        </div>
      </div>
      <div className="my-16 overflow-hidden md:my-0 md:w-1/2">
        <img className="w-full h-full" src={graph} alt="trading graph" />
      </div>
    </div>
  );
};

export default memo(DiscoverNewDynamicsOfTrading);
