import { Close, Menu } from "@mui/icons-material";
import { memo, useState } from "react";
import meritradeLogo2 from "../asset/images/meritrade-logo-2.png";

const Navigation: React.FC = () => {
  const [menu, setMenu] = useState<boolean>(false);

  return (
    <div className="fixed top-0 left-0 z-50 flex flex-col items-center justify-around w-full gap-2 px-8 py-4 bg-green-800 bg-cover md:px-16 item-center md:flex-row bg-green">
      <div className="relative flex flex-row justify-between w-full h-auto md:w-auto item-center">
        <a href="/">
          <img
            className="w-24 h-12 motion-safe:animate-bounce md:w-auto md:h-16"
            src={meritradeLogo2}
            alt="meristem logo"
          />
          <div className="w-full text-sm font-bold text-right text-primary-dark">
            by Meristem
          </div>
        </a>
        {menu ? (
          <Close
            onClick={() => setMenu(!menu)}
            className="w-8 h-12 text-6xl text-primary-light md:hidden"
          />
        ) : (
          <Menu
            onClick={() => setMenu(!menu)}
            className="w-8 h-12 text-6xl text-primary-light md:hidden"
          />
        )}
      </div>
      <div
        className={`flex flex-col md:items-center md:justify-center w-full md:h-auto gap-2 md:flex-row justify-start ${
          menu ? "" : "hidden md:flex"
        }`}
      >
        <div className="flex flex-col justify-center w-full md:flex-row">
          {[
            { id: "1", title: "How it works", link: "/#how-it-works" },
            { id: "2", title: "Margin Trading", link: "/margin-trading" },
            { id: "3", title: "Why Meritrade", link: "/#why-meritrade" },
            { id: "4", title: "Platform", link: "/#platform" },
            { id: "5", title: "FAQ", link: "https://knowledge.meritrade.com" },
            { id: "6", title: "Downloads", link: "/#downloads" },
          ].map((item, _) => (
            <a
              key={item.id}
              href={item.link}
              target={`${item.title.toLowerCase() === "faq" && "_blank"}`}
              onClick={() => setMenu(!menu)}
              className="transition duration-500 ease-in-out delay-100 hover:translate-y-1 hover:scale-110"
            >
              <div className="w-auto p-4 rounded-md text-primary-light hover:bg-primary-dark">
                {item.title}
              </div>
            </a>
          ))}
        </div>
        <div
          className={`md:max-w-max gap-2 flex flex-col justify-around container pb-8 md:pb-0 item-center md:flex-row border-primary-light hover:border-solid ${
            menu ? "" : "hidden md:flex"
          }`}
        >
          <a href="https://app.meritrade.com">
            <div className="w-auto p-4 text-sm text-center underline capitalize rounded-lg md:mr-2 md:font-semibold md:w-20 text-primary-light hover:bg-primary-dark hover:text-primary-light">
              Login
            </div>
          </a>
          <a href="https://app.meritrade.com/auth/register">
            <div className="w-auto p-4 text-sm font-semibold text-center rounded-lg md:border-primary-dark bg-primary-light text-primary-deep-dark hover:bg-primary-dark hover:text-primary-light md:hover:bg-primary-light md:bg-primary-deep-dark md:text-primary-light md:hover:text-primary-deep-dark">
              Create an Account
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default memo(Navigation);
