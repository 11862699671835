import React, { memo } from "react";
import checked from "../asset/images/checked.png";
import mobiles from "../asset/images/meritrade-mobile-phones.png";

type MTBenefitProps = {
  id: string;
  title: string;
  image: string;
};

export const benefits: MTBenefitProps[] = [
  {
    id: "1",
    title: "Incresed Financial Gain",
    image: checked,
  },
  {
    id: "2",
    title: "Upgraded Purchasing Power",
    image: checked,
  },
  {
    id: "3",
    title: "Minimum Charges",
    image: checked,
  },
  {
    id: "4",
    title: "Diversification of Equity Portfolio",
    image: checked,
  },
  {
    id: "5",
    title: "Hassle-Free Repayments",
    image: checked,
  },
];

export const BenefitsOfMarginTrading: React.FC<{
  benefits: MTBenefitProps[];
}> = ({ benefits }) => {
  return (
    <div className="flex flex-col justify-between w-full h-auto my-16 md:flex-row">
      <div className="flex flex-col items-start justify-start w-full gap-8 md:1/4">
        <h1 className="text-4xl opacity-80">
          Benefits Of Margin
          <br /> Trading
        </h1>
        <div className="flex flex-col">
          {benefits.map((item) => (
            <span
              key={item.id}
              className="flex flex-row justify-start gap-2 p-1 text-md item-center"
            >
              <img className="w-8 h-8" src={item.image} alt="checked" />
              {item.title}
            </span>
          ))}
        </div>
      </div>
      <div className="flex flex-col w-full md:w-3/4 md:flex-col">
        {" "}
        <img className="w-full h-96" src={mobiles} alt="checked" />
      </div>
    </div>
  );
};

export default memo(BenefitsOfMarginTrading);
