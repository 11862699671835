import React, { memo } from "react";
import BenefitsOfMarginTrading, {
  benefits,
} from "../../components/BenefitsOfMarginTrading";
import Footer from "../../components/Footer";
import MagicTradingOnMeritrade from "../../components/MagicTradingOnMeritrade";
import MarginTradingFAQ from "../../components/MarginTradingFAQ";
import Navigation from "../../components/Navigation";
import ScrollDown from "../../components/ScrollDown";
import WhatIsRequired, { requirements } from "../../components/WhatIsRequired";

const MarginTrading: React.FC = () => {
  return (
    <div className="justify-between w-full h-auto px-8 pt-32 overflow-y-auto bg-cover text-primary-light md:px-16 bg-onboarding-gradient">
      <Navigation />
      <MagicTradingOnMeritrade />
      <ScrollDown />
      <BenefitsOfMarginTrading benefits={benefits} />
      <WhatIsRequired requirements={requirements} />
      <MarginTradingFAQ />
      <Footer />
    </div>
  );
};

export default memo(MarginTrading);
