import { memo } from "react";
import meritradeMobile from "../asset/images/meritrade-mobile-phones.png";
import mobile from "../asset/images/mobile.png";

const PoweredByMeristem: React.FC = () => {
  return (
    <div className="w-full gap-4 p-8 md:flex md:flex-row md-p-0">
      <div className="flex flex-col w-full pt-8 md:w-1/3">
        <div className="w-full mb-8 text-4xl font-black text-center md:text-start">
          Powered by <span className="md:block">Meristem</span>
        </div>
        <div className="text-md leading-[2] text-primary-light w-full">
          Meristem Stockbrokers Limited (MSBL) is a member of the Nigerian
          Exchange Group (NGX), and is licensed by the Securities and Exchange
          Commission (SEC) in Nigeria. Our professional advisory services are
          always based on carefully considered research and delivered with
          objective integrity from a crop of professionals that remain the best
          hands within the financial services industry.
        </div>
      </div>
      <div className="flex flex-col w-full md:w-2/3">
        <div className="hidden md:flex">
          <img src={meritradeMobile} className="w-full h-auto" alt="" />
        </div>
        <div className="md:hidden">
          <img src={mobile} className="w-full h-auto" alt="mobile" />
        </div>
      </div>
    </div>
  );
};

export default memo(PoweredByMeristem);
