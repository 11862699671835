import React, { memo } from "react";
import detachment from "../asset/downloads/detachment_form.pdf";
import marginAgreement from "../asset/downloads/margin_agreement.pdf";
import risk from "../asset/downloads/risk.pdf";
import mobiles from "../asset/images/meritrade-mobile-phones.png";

type WhatIsRequiredProps = {
  id: string;
  item: string;
  link: string;
};

export const requirements: WhatIsRequiredProps[] = [
  {
    id: "1",
    item: "Download Agreement Here",
    link: marginAgreement,
  },
  {
    id: "2",
    item: "Risk Acceptance Criteria",
    link: risk,
  },
  {
    id: "3",
    item: "Detachment Form",
    link: detachment,
  },
];

export const WhatIsRequired: React.FC<{
  requirements: WhatIsRequiredProps[];
}> = ({ requirements }) => {
  return (
    <div className="flex flex-col w-full gap-8 my-16 md:justify-between md:flex-row">
      <div className="flex flex-col items-start justify-start order-2 w-full px-8 my-8 text-center md:w-1/2">
        <h1 className="text-4xl">What is Required?</h1>
        <p className="py-8 text-sm text-start opacity-80">
          Getting a Margin Trading account just got easier. Once you have a
          MeriTrade account, you simply need to fill a Margin Trading Agreement.
          As soon as that is finalized, you are good to go!
        </p>
        <div className="flex flex-col">
          {requirements.map((item) => (
            <a
              href={item.link}
              key={item.id}
              className="p-1 text-sm underline text-start"
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.item}
            </a>
          ))}
        </div>
      </div>
      <div className="order-1 hidden w-full md:w-1/2 md:flex">
        {" "}
        <img className="w-full h-96" src={mobiles} alt="checked" />
      </div>
    </div>
  );
};

export default memo(WhatIsRequired);
