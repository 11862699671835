import { memo } from "react";
import { Link } from "react-router-dom";

const Investment: React.FC = () => {
  return (
    <div className="flex flex-col items-center w-full gap-6 mt-32 text-center rounded-lg bg-slate-50 bg-opacity-5">
      <h1 className="text-center text-3xl font-bold leading-[2] text-primary-light w-full">
        Investment is the <br />
        ENGINE of your WEALTH. INVEST today. SECURE Tomorrow
      </h1>
      <Link
        to="https://app.meritrade.com/auth/register"
        className="p-4 px-16 mb-8 font-semibold text-center border border-solid rounded-lg text-md hover:bg-secondary text-primary-light border-secondary bg-primary-deep-dark shrink-0"
      >
        Create an Account
      </Link>
    </div>
  );
};

export default memo(Investment);
