import { memo } from "react";
import freeResearch from "../asset/images/free-research.png";
import lowCapital from "../asset/images/low-capital.png";
import tradeOnTheGoIcon from "../asset/images/trade-on-the-go.png";

type DiscoverType = {
  id: string;
  title: string;
  message: string;
  image: string;
};

export const discoverList: DiscoverType[] = [
  {
    id: "1",
    title: "Trade on the go",
    message:
      "We have web and mobile applications to enable you trade from anywhere at anytime.",
    image: tradeOnTheGoIcon,
  },
  {
    id: "2",
    title: "Low Capital",
    message:
      "With as low as N10,000, you can open an account, Our trading fees and commissions are transparent and clear.",
    image: lowCapital,
  },
  {
    id: "3",
    title: "Up-To-Date Market Information",
    message:
      "We lead you by the hand, get the latest stock recommendations, investment tips you need on the Platform.",
    image: freeResearch,
  },
];

const DiscoverTheTrueMeaning: React.FC<{ discoverList: DiscoverType[] }> = ({
  discoverList,
}) => {
  return (
    <div
      id="why-meritrade"
      className="flex flex-col items-end justify-end w-full h-auto px-4 mt-32 mb-16"
    >
      <div className="flex flex-col items-center justify-center w-full gap-4 p-4 mt-36 md:mt-24">
        <div className="text-start md:text-center text-5xl leading-[1.5] text-primary-light self-start w-full">
          Discover the true meaning of <br /> financial security
        </div>
        <div className="mb-8 font-medium md:text-center text-primary-light opacity-60">
          Determine the dynamics of trading, and take control of your financial
          security
        </div>
      </div>
      <div className="flex flex-col justify-between w-full gap-6 overflow-hidden overflow-x-auto md:flex-row">
        {discoverList.map((item) => (
          <div
            key={item.id}
            className="flex flex-row items-start justify-center w-full h-auto gap-6 p-6 rounded-lg shadow-md bg-opacity-5 bg-slate-50"
          >
            <img src={item.image} className="w-16" alt="" />
            <div className="flex flex-col items-start w-64 gap-4 ">
              <div className="text-center font-['Inter'] font-semibold text-primary-light">
                {item.title}
              </div>
              <div className="text-sm font-['Inter'] leading-[22px] text-[rgba(249,_249,_249,_0.7)] w-full">
                {item.message}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(DiscoverTheTrueMeaning);
