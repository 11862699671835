import React, { memo } from "react";

type VideoListItemProps = {
  id?: string;
  title: string;
  src: string;
};

export const videos: VideoListItemProps[] = [
  {
    id: "1",
    title: "Trade stock on the go on meritrade.com",
    src: "https://www.youtube.com/embed/GOeum73Ew88",
  },
  {
    id: "2",
    title: "How to trade with meritrade.com",
    src: "https://www.youtube.com/embed/isPRBG66JK0",
  },
  {
    id: "3",
    title: "Meristem Margin Lending",
    src: "https://www.youtube.com/embed/-AgYVfI1WVs",
  },
];

const VideoList: React.FC<{
  videos: VideoListItemProps[];
  showVideo: boolean;
}> = ({ videos, showVideo }) => {
  return (
    <div
      id="videos"
      className={`w-full h-auto ${showVideo ? "flex" : "hidden"}`}
    >
      <ul className="flex flex-col items-center w-full gap-4 justify-evenly md:flex-row ">
        {videos.map((item) => (
          <li
            key={item.id}
            className="flex flex-col items-center w-full rounded-lg shadow-md md:w-1/3 bg-opacity-5 bg-slate-50 shadow-green-50"
          >
            <iframe
              className="w-full h-72"
              src={item.src}
              title={item.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
            <p className="my-8 text-white">{item.title}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(VideoList);
